
import { GearIcon, SignalIcon, JustGoIcon, AdvertisementIcon, PeopleInAShieldIcon } from '../../assets/icons'
import { React } from 'lib'
import { Theme } from 'app'

export const PremiumContent = [
  {
    title: 'Personalised\nSafety',
    description: 'Adjust your safety settings\nto protect different\nactivities & journeys.',
    icon: <GearIcon size={48} color={Theme.colors.grad1}/>,
  },
  {
    title: 'live location\ntracking',
    description: 'Share your journey with Key\nContacts and send your live\nlocation Beconwing an alert.',
    icon: <SignalIcon size={48} color={Theme.colors.grad1}/>,
  },
  {
    title: 'Journey Key\nContacts',
    description: 'Link specific Key Contacts\nto different journeys & your\nfavourite destinations.',
    icon: <PeopleInAShieldIcon size={48} color={Theme.colors.grad1}/>,
  },
  {
    title: 'Ad-free\nExperience',
    description: 'Enjoy automated journey\nprotection without ads\nor interruptions.',
    icon: <AdvertisementIcon size={48} color={Theme.colors.grad1}/>,
  },
  {
    title: 'Unlock\n‘Just Go’',
    description: 'Protect journeys when you don’t have a specific\nroute or destination. ',
    icon: <JustGoIcon size={48} color={Theme.colors.grad1}/>,
  },
] as const
