import { Theme } from 'app'
import { React, View, StyleSheet, Text } from 'lib'
import Slider, { Settings } from 'react-slick'
import { PremiumContent } from '../../components/premium/content'

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 20000,
  centerMode: true,
  centerPadding: '0px',
  responsive: [
    {
      breakpoint: Theme.breakpoints.xxlarge,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        centerPadding: '25px',
      },
    },
    {
      breakpoint: Theme.breakpoints.xlarge,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: '60px',
      },
    },
    {
      breakpoint: Theme.breakpoints.large,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: '10px',
      },
    },
    {
      breakpoint: Theme.breakpoints.largeish,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerPadding: '80px',
      },
    },
    {
      breakpoint: Theme.breakpoints.mid,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerPadding: '30px',
      },
    },
    {
      breakpoint: Theme.breakpoints.small + 150,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '120px',
      },
    },
    {
      breakpoint: Theme.breakpoints.small,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '40px',
      },
    },
    {
      breakpoint: Theme.breakpoints.smallish,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0px',
      },
    },
  ],
}

const PremiumCard: React.FC<typeof PremiumContent[number]> = ({ title, description, icon }) => (
  <View style={styles.cardWrapper}>
    <View style={styles.card}>
      <View style={styles.iconWrapper}>
        <View style={styles.iconInnerWrapper}>{icon}</View>
      </View>

      <Text variant={`h5 capitalize textCenter`} style={styles.text} text={title}/>
      <Text variant={`p3 textCenter`} style={styles.text} text={description}/>
    </View>
  </View>
)

const PremiumCarousel: React.FC = () => {
  return (
    <View style={styles.listWrapper}>
      <Text variant={`h3 marginBottom:5 marginHorizontal:2 capitalize textCenter`} text={'Enjoy The Benefits Of Becon+'}/>
      <View style={styles.innerListWrapper}>
        <Slider {...settings}>
          {PremiumContent.map((content, key) => <PremiumCard key={key} {...content}/>)}
        </Slider>
      </View>
    </View>
  )
}

const cardRadius = 18

const styles = StyleSheet.createComponentStyleSheet({
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '.slick-slider': {
      [Theme.media.up('xxlarge')]: {
        justifyContent: 'center',
      },
    },
    '.slick-slide > div': {
      display: 'grid',
      'place-items': 'center',
    },
  },
  innerListWrapper: {
    width: '100%',
    cursor: 'grab',
  },

  cardWrapper: {
    width: 'fit-content',
    height: 'fit-content',

    padding: 2,
    borderRadius: cardRadius,
    background: Theme.colors.gradientBorder,
  },
  card: {
    minWidth: 340,
    maxWidth: 340,
    minHeight: 340,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: Theme.spacing(3),
    padding: `${Theme.spacing(5)}px ${Theme.spacing(3)}px`,

    backgroundColor: Theme.colors.greyscale6,
    borderRadius: cardRadius - 2, // 2 is the outer wrapper padding
    [Theme.media.down('smallish')]: {
      padding: `${Theme.spacing(4)}px ${Theme.spacing(2)}px`,
      minWidth: 250,
      maxWidth: 250,
    },
  },
  text: {
    whiteSpace: 'pre-line',
  },
  iconWrapper: {
    width: 'fit-content',
    borderRadius: '50%',
    padding: 5,
    background: `linear-gradient(90deg,${Theme.colors.grad1}, ${Theme.colors.star}) border-box`,
  },
  iconInnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: Theme.colors.white,
    padding: Theme.spacing(3),
  },
})

export default PremiumCarousel
